<template>
  <div>
    <div
      v-for="client in clients"
      :key="client"
      class="client"
    >
      <router-link :to="{ name: 'Message-show', params: { id: client.id } }">
        <b-card>
          <b-card-header>
            <b-card-title>
              <h3>Użytkownik: {{ client.name }}</h3>
            </b-card-title>
          </b-card-header>
          <b-img
            src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
            class="col-xl-1 rounded"
          />
          <b-card-body style="font-size: 1.3em; color: black; font-weight: 500;">
            {{ client.id }} || {{ client.name }} || {{ client.email }} || {{ client.created_at }}
          </b-card-body>
        </b-card>
      </router-link>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'
import { BCard, BCardText, BImg } from 'bootstrap-vue'

export default {
  name: 'Clients',
  components: {
    BCard,
    BCardText,
    BImg,
  },
  data() {
    return {
      clients: [],
      client: {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        notes: '',
      },
      errors: [],
    }
  },
  mounted() {
    document.title = 'Lista klientów - Pickmode'

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}my-clients`, config)
      .then(response => {
        this.clients = response.data.clients
      })
      .catch(error => {
        swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      })
  },
}
</script>
